import React from 'react'
import Footer from './Footer'
import Navbar from './Navbar'
import '../styles/index.scss'
import layoutStyles from '../styles/components/layout.module.scss'

const Layout = (props) => {
  return (
    <div className={layoutStyles.container}>
      <Navbar />
      <div className={`${layoutStyles.content}`}>
        {props.children}
      </div>
      <Footer />
    </div>
  )
}

export default Layout
