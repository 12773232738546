import React, { Component } from 'react'
import { Link } from 'gatsby'
import TwitterIcon from "../../static/assets/twitter-brands.svg";
import GithubIcon from "../../static/assets/github-brands.svg";
import headerStyles from '../styles/components/header.module.scss'
import layoutStyles from '../styles/components/layout.module.scss'

export default class Navbar extends Component {
  constructor(props) {
    super(props)
    this.toggleBurger = this.toggleBurger.bind(this)

    this.state = {
      burgerIsActive: false
    }
  }

  toggleBurger() {
    this.setState((prevState) => ({
      burgerIsActive: !prevState.burgerIsActive
    }))
  }

  render() {
    return (
      <nav
        className={`${layoutStyles.navbar} navbar is-fixed-top container`}
        role="navigation"
        aria-label="main navigation"
      >
        <div className={`${layoutStyles.brand} navbar-brand`}>
          <Link className={`${headerStyles.title} navbar-item navbar-logo`} to="/">
            DERRICKS<span className="navbar-accent">DOCS</span>
          </Link>

          <a
            role="button"
            className={'navbar-burger burger ' + (this.state.burgerIsActive && 'is-active')} 
            aria-label="menu"
            aria-expanded="false"
            onClick={this.toggleBurger}
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
        </div>

        <div className={'navbar-menu ' + (this.state.burgerIsActive && 'is-active')}>
          <div className="navbar-end">
            <Link className={`${headerStyles.navItem} navbar-item is-size-7`} to="/">Home</Link>
            <Link className={`${headerStyles.navItem} navbar-item is-size-7`} to="/about/">About</Link>
            <a className={`${headerStyles.svg} ${headerStyles.navItem}`} href="https://twitter.com/robustdj">
              <TwitterIcon />
            </a>
            <a className={`${headerStyles.svg} ${headerStyles.navItem}`} href="https://github.com/robustdj">
              <GithubIcon />
            </a>
          </div>
        </div>
      </nav>
    )
  }
}