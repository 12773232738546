import React from 'react'
import { Link } from 'gatsby'
import footerStyles from '../styles/components/footer.module.scss'

const Footer = () => (
  <footer className={`${footerStyles.footer} footer`}>
    <div className="content has-text-centered">
      <p><Link to="/">Derrick's Docs</Link> © 2016</p>
    </div>
  </footer>
)

export default Footer
